import Link from 'next/link';
import React from "react";
import Image from 'next/image';
import {useEffect, useState} from "react";
import {ctaEvent} from "../../../utils/events";

export async function getStaticProps(context) {
    return {
        props: {}, // will be passed to the page component as props
    }
}

export default function Header() {

    return (
        <>
            <div className="container">
                <div className="header-text">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>EXTEND YOUR TAX RETURN<br/>THROUGH <span
                                style={{"color": "#8bc340"}}>OCTOBER 15th</span></h1>
                            <Link href="/submit-extension" prefetch={false} onClick={()=>ctaEvent("hero-cta")}>
                                <a onClick={() => ctaEvent("hero-cta")}
                                href="/submit-extension" className="submitirs cta-btn" id="hero-cta" >Submit Tax Extension</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
